import React, { useState } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Logo from "../images/zvistra-vertical.png";
import "./MainMenu.css";

const burgerStyles = {
  bmBurgerButton: {
    display: "block",
    position: "absolute",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "23px",
  },
}

function MainMenu() {
  const [menuState, setMenuState] = useState(false);

  let isMenuOpen = function (state) {
    setMenuState(state.isOpen)
  };

  function subMenuClick(index) {
    let isOpen = document.querySelectorAll(".h-dropdown-content")[index].style.display;

    if (isOpen === "none" || isOpen === "") {
      document.querySelectorAll(".h-dropdown-content")[index].style.display = "block";
      document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(90deg)";
    } else {
      document.querySelectorAll(".h-dropdown-content")[index].style.display = "none";
      document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(0deg)";
    }
  }

  return (
    <StaticQuery query={graphql`
		{
			wpgraphql {
                menus(where: {slug: "header-menu"}) {
                  edges {
                    node {
                      name
                      menuItems {
                        edges {
                          node {
                            url
                            label
                          }
                        }
                      }
                    }
                  }
                }
            }
	  	}
	  
	`} render={(props) => {
        console.log(props)
        // const menu = props.wpgraphql.menus.edges[0].node.menuItems.edges;
        return (
          <React.Fragment>
            <div id="headerContainer" className="w-full m-0 p-0 bg-cover bg-bottom">
              <div className="flex w-full justify-between header container mx-auto">
                <div className=" w-full lg:w-3/12 flex items-center">
                  <a href="https://zvistra.gr">
                    <img className="p-4 h-logo cursor-pointer" src={Logo} />
                  </a>
                </div>
                <div className="w-7/12 hidden lg:flex items-center justify-end">
                  <div className="inline-flex h-full" >
                    <ul className="flex flex-wrap  md:px-2  text-sm md:text-base h-firstlevel">
                      <li className="h-dropdown relative mx-1 px-1 py-2 group  ml-8  md:mb-0 font-semibold whitespace-no-wrap text-gray-600 hover:text-blue-800" >
                        <a href="https://zvistra.gr/application">
                          <span>Εφαρμογή</span>
                        </a>
                        <div className="h-dropdown-content">
                          <a href="https://zvistra.gr/application/calendar">
                            <span>Ημερολόγιο Υποχρεώσεων</span>
                          </a>
                          <a href="https://zvistra.gr/application/chat">
                            <span>Συνομιλία</span>
                          </a>
                          <a href="https://zvistra.gr/application/invoices">
                            <span>Παραστατικά</span>
                          </a>
                          <a href="https://zvistra.gr/application/documents">
                            <span>Εταιρικά  Έγγραφα</span>
                          </a>
                          <a href="https://zvistra.gr/application/reports">
                            <span>Οικονομικές Αναφορές</span>
                          </a>
                        </div>
                      </li>
                      <li className="relative mx-1 px-1 py-2 group  ml-8  md:mb-0 font-semibold whitespace-no-wrap text-gray-600 hover:text-blue-800" >
                        <a href="https://zvistra.gr/pricing" >
                          <span className="">Πακέτα Υπηρεσιών</span>
                        </a>
                      </li>
                      <li className="h-dropdown relative mx-1 px-1 py-2 group  ml-8  md:mb-0 font-semibold whitespace-no-wrap text-gray-600 hover:text-blue-800" >
                        <span className="">Χρήσιμα</span>
                        <div className="h-dropdown-content">
                          <a href="https://blog.zvistra.gr">
                            <span className="">Blog</span>
                          </a>
                          <a href="/help-center">
                            <span className="">Κέντρο Βοήθειας</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="w-2/12 hidden lg:flex items-center justify-end">
                  <a href="https://app.zvistra.gr" className="h-application">
                    <span>
                      Είσοδος
						      </span>
                  </a>
                </div>
              </div>
              <div id="mainMenu"></div>
            </div>
          </React.Fragment>
        )
      }} />
  )
}

export default MainMenu;