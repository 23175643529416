import React, { useState } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import axios from "axios"
import FacebookLogo from "../images/facebook.svg";
import LinkedIn from "../images/linkedin.png";
import FooterLogo from "../images/zvistra-vertical-white.png";

import "./FooterMenu.css";





function FooterMenu() {
  const [formElements, setFormElements] = useState({
    name: "",
    email: "",
    theme: "",
    subject: ""
  });


  function handleSubmit(event) {
    event.preventDefault();

    axios.post('https://dev-api.zvistra.gr/el/contact/customer-support', {
      email: formElements.email,
      name: formElements.name,
      subject: formElements.subject,
      message: formElements.theme,
    })
      .then(function (response) {
        alert(response.data.data)
      })
      .catch(function (error) {
        alert(error.response.data.messages[0])
      });
  }

  const handleFormChange = (field) => {
    return (e) => {
      let newFormElements = { ...formElements };
      newFormElements[field] = e.target.value;
      setFormElements(newFormElements);
    }
  }

  return (
    <StaticQuery query={graphql`
		{
            wpgraphql {
                menu: menus(where: {slug: "footer-menu"}) {
                  edges {
                    node {
                      name
                      slug
                      menuItems {
                        edges {
                          node {
                            url
                            label
                          }
                        }
                      }
                    }
                  }
                }
                generalPages: menus(where: {slug: "footer-general-pages"}) {
                  edges {
                    node {
                      name
                      slug
                      menuItems {
                        edges {
                          node {
                            url
                            label
                          }
                        }
                      }
                    }
                  }
                }
                productCategories: menus(where: {slug: "footer-product-category-menu"}) {
                  edges {
                    node {
                      name
                      slug
                      menuItems {
                        edges {
                          node {
                            url
                            label
                          }
                        }
                      }
                    }
                  }
                }
              }
                stripe: file(relativePath: { eq: "stripe.png" }) {
                    childImageSharp {
                    fixed{
                        ...GatsbyImageSharpFixed
                    }
                }
            }
	  	}
	  
	`} render={props => {
        console.log(props)
        // const footerMenu = props.wpgraphql.menu.edges[0].node.menuItems.edges;
        // const generalMenu = props.wpgraphql.generalPages.edges[0].node.menuItems.edges;
        // const productlMenu = props.wpgraphql.productCategories.edges[0].node.menuItems.edges;


        return (
          <footer className="w-full">
            <div className="footer">
              <div className="container mx-auto clr-white flex flex-wrap f-center-custom">
                <div className=" order-1 w-full md:w-1/2 lg:w-1/3 px-4 mx-auto mx-0">
                  <img className="p-4 f-logo md:mx-0 mx-auto" src={ FooterLogo } />

                  <h3 className="text-2xl font-medium pt-4 pb-2 text-center md:text-left text-white">Σχετικά με εμάς</h3>
                  <p className="text-center md:text-left mw-360 mx-auto md:mx-0">
                    Η Zvistra είναι μια καινοτόμα λογιστική εφαρμογή για ελεύθερους επαγγελματίες και επιχειρήσεις, που αναζητούν ποιοτικές υπηρεσίες σε χαμηλό κόστος.
							</p>
                </div>
                <div className="order-3 lg:order-2 w-full lg:w-1/3 flex justify-center px-8 lg:px-0 my-12 lg:my-0">
                  <div>
                    <h4 className="text-center font-bold">ΧΡΕΙΑΖΕΣΤΕ ΒΟΗΘΕΙΑ;</h4>
                    <h5 className="text-center mt-4 mb-4 clr-b4">Επικοινωνήστε μαζί μας</h5>
                    <div className="flex flex-wrap">
                      <div className="pl-0 md:pl-2 w-full md:w-1/2">
                        <input value={formElements.name} onChange={handleFormChange("name")} className="w-full f-input" placeholder="Ονοματεπώνυμο" />
                      </div>
                      <div className="pl-0 md:pl-2 w-full mt-4 md:mt-0 md:w-1/2">
                        <input value={formElements.email} onChange={handleFormChange("email")} className="w-full f-input" placeholder="Email" />
                      </div>
                      <div className="pl-0 md:pl-2 w-full mt-4">
                        <input value={formElements.theme} onChange={handleFormChange("theme")} className="w-full f-input" placeholder="Θέμα" />
                      </div>
                      <div className="pl-0 md:pl-2 w-full mt-4">
                        <textarea value={formElements.subject} onChange={handleFormChange("subject")} className="w-full f-input" placeholder="Γράψτε το μήνυμά σας εδώ…" ></textarea>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-center">
                      <button onClick={handleSubmit} className="f-sub-btn">
                        Αποστολή
									</button>
                    </div>
                  </div>

                </div>
                <div className="order-2 md:w-1/2 lg:order-3 w-full lg:w-1/3 flex justify-center md:justify-center mt-12 lg:mt-0">
                  <div className="text-center md:text-left">
                    <div>
                      <h5 className="mb-4">ΣΤΕΙΛΤΕ ΜΑΣ EMAIL</h5>
                      <a href="mailto:info@zvistra.gr" className="clr-b4">info@zvistra.gr</a>
                    </div>
                    <div className="mt-12">
                      <h5>ΑΚΟΛΟΥΘΗΣΤΕ ΜΑΣ</h5>
                      <div className="flex mt-4 justify-center justify-start">
                        <div>
                            <a href="https://www.facebook.com/zvistraapp" target="_blank">
                              <img className="f-social-img" src={ FacebookLogo } />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/zvistra" target="_blank">
                              <img className="ml-2 f-social-img" src={ LinkedIn } />
                            </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="f-sub-footer flex flex-wrap justify-around items-center text-white">
              <div className="w-full my-4 lg:my-0 lg:w-1/3 xl:w-1/2 order-2 lg:order-1 text-center">
                <p className="f-zv-clr">All copyrights reserved @ 2020</p>
              </div>
              <div className="f-sub-footer-max flex flex-wrap justify-center order-1 lg:order-2 w-full lg:w-2/3 xl:w-1/2 mt-4 lg:mt-0 clr-b4">
                <a href="https://zvistra.gr/privacy-policy">
                  <span className=" w-full flex justify-center md:w-auto md:ml-4">Πολιτική Απορρήτου</span>
                </a>
                {/* <a href="/">
							<span className=" w-full md:w-auto  flex justify-center md:w-auto md:ml-4">Application Subscription Agreement</span>
						</a>
						<a href="/">
							<span className=" w-1/2 flex justify-center md:w-auto md:ml-4">Όροι & Προυποθέσεις </span>
						</a> */}
              </div>
            </div>

          </footer>
        )
      }} />
  )};

  export default FooterMenu;