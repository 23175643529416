
import React, { useState } from "react"
import MainMenu from "./MainMenu"
import FooterMenu from "./FooterMenu"
import { Helmet } from "react-helmet"
import { elastic as Menu } from 'react-burger-menu'
import Logo from "../images/zvistra-vertical-white.png";
import ChevronDown from "./../images/chev-down.svg"
import "./MainMenu.css";


const burgerStyles = {
	bmBurgerButton: {
		display: "block",
		position: "absolute",
		width: "36px",
		height: "30px",
		right: "36px",
		top: "23px",
	},
}

function Layout({ children, metaData }) {

	const [menuState, setMenuState] = useState(false);

	let isMenuOpen = function (state) {
		setMenuState(state.isOpen)
	};

	function subMenuClick(index) {
		let isOpen = document.querySelectorAll(".m-dropdown-content")[index].style.display;

		if (isOpen === "none" || isOpen === "") {
			document.querySelectorAll(".m-dropdown-content")[index].style.display = "block";
			document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(90deg)";
		} else {
			document.querySelectorAll(".m-dropdown-content")[index].style.display = "none";
			document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(0deg)";
		}
	}

	return (
		<div>
			{metaData && (
				<Helmet>
					<html lang={"fr"} />
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />
					<link rel="canonical" href={metaData.canonical} />
					<meta
						name="viewport"
						content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
					/>

					<meta property="og:title" content={metaData.fbTitle} />
					<meta property="og:description" content={metaData.fbDescription} />
					<meta property="og:url" content={metaData.canonical} />
					<meta property="og:type" content={metaData.fbType} />
					<meta property="og:locale" content={metaData.locale} />
					<meta property="og:image" content={metaData.image} />
					<meta property="og:image:width" content="598" />
					<meta property="og:image:height" content="399" />

				</Helmet>
			)}

			<div className="md:hidden">
				<Menu styles={burgerStyles} isOpen={menuState} onStateChange={isMenuOpen} >
					<div>
						<a href="/">
							<img style={{ width: "215px", maxWidth: "none", outline: "none" }} className="p-4 h-logo" src={ Logo } />
						</a>
					</div>
					<div className="relative h-menu-item outline-none" style={{ "marginTop": "1rem" }}>
						<span onClick={() => subMenuClick(0)} className="flex outline-none">
							<a className="font-semibold">Εφαρμογή</a>
							<img className="h-arrow-d h-chev-down outline-none" src={ChevronDown} />
						</span>
						<div className="m-dropdown-content">
							<a href="https://zvistra.gr/application">
								<span>Η εφαρμογή</span>
							</a>
							<a href="https://zvistra.gr/application/calendar">
								<span>Ημερολόγιο Υποχρεώσεων</span>
							</a>
							<a href="https://zvistra.gr/application/chat">
								<span>Συνομιλία</span>
							</a>
							<a href="https://zvistra.gr/application/invoices">
								<span>Παραστατικά</span>
							</a>
							<a href="https://zvistra.gr/application/documents">
								<span>Εταιρικά  Έγγραφα</span>
							</a>
							<a href="https://zvistra.gr/application/reports">
								<span>Οικονομικές Αναφορές</span>
							</a>
						</div>
					</div>
					<div className="h-menu-item" style={{ "marginTop": "1rem" }}><a href="https://zvistra.gr/pricing" className="font-semibold">Πακέτα Υπηρεσιών</a></div>
					{/* <div className="relative menu-item outline-none" style={{"marginTop":"1rem"}}>
              <span className="flex outline-none">
                <a className="font-semibold">Χρήσιμα</a>
                <img onClick={ () => subMenuClick(1) } className="arrow-d chev-down outline-none" src="/static/img/svgs/chev-down.svg"  />
              </span>
              <div className="dropdown-content">
                <Link href="/blog">
                  <span>Blog</span>
                </Link>
                <Link href="/help-center">
                  <span>Κέντρο Βοηθείας</span>
                </Link>
              </div>
            </div> */}

					<div className="h-menu-item absolute" style={{ "bottom": "73px" }}><a href="https://app.zvistra.gr/register">Εγγραφή</a></div>
					<div className="h-menu-item absolute" style={{ "bottom": "30px" }}><a href="https://app.zvistra.gr">Είσοδος στην εφαρμογή</a></div>
				</Menu>
			</div>

			<MainMenu />
			{children}
			<FooterMenu />
		</div>

	)
};

export default Layout
